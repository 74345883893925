
export default {
  state: {
    stateProfileData: {},
    stateCompanyPhoneType: [],
    stateCompanyDocumentType: [],
    stateCompanies: [],
    stateBureauAuthStatuses: {},
    stateBureauDataStatuses: {},
  },
  mutations: {
    setStateBureauStatus(state, {stateBureauAuthStatuses, stateBureauDataStatuses}) {
      state.stateBureauAuthStatuses = stateBureauAuthStatuses;
      state.stateBureauDataStatuses = stateBureauDataStatuses;
    },
    setStateCompanies(state, companies) {
      state.stateCompanies = companies;
    },
    clearStateCompanies(state) {
      state.stateCompanies = [];
    },
    setStateProfileData(state, stateProfileData) {
      state.stateProfileData = stateProfileData;
    },
    clearStateProfileData(state) {
      state.stateProfileData = {};
    },
    setStateCompanyPhoneType(state, stateData) {
      state.stateCompanyPhoneType = stateData;
    },
    clearStateCompanyPhoneType(state) {
      state.stateCompanyPhoneType = [];
    },
    setStateCompanyDocumentType(state, stateData) {
      state.stateCompanyDocumentType = stateData;
    },
    clearStateCompanyDocumentType(state) {
      state.stateCompanyDocumentType = [];
    },
  },
  getters: {
    stateCompanies: s=>s.stateCompanies,
    stateProfileData: s=>s.stateProfileData,
    stateCompanyPhoneType: s=>s.stateCompanyPhoneType,
    stateCompanyDocumentType: s=>s.stateCompanyDocumentType,
    stateBureauAuthStatuses: s=>s.stateBureauAuthStatuses,
    stateBureauDataStatuses: s=>s.stateBureauDataStatuses,
  },
  actions: {
    async loadStateProfileData({commit, dispatch, getters}, userId) {
      if (getters.stateProfileData?.id === userId) {
        return getters.stateProfileData;
      }
      const profile = await dispatch('apiGetProfile', userId);
      if (!profile?.email_address) {
        await commit('clearStateProfileData');
        return profile;
      }
      profile.id = userId;
      await commit('setStateProfileData', profile);
      return profile;
    },
    async loadStateCompanyPhoneType({commit, dispatch, getters}) {
      if (getters.stateCompanyPhoneType.length) {
        return getters.stateCompanyPhoneType;
      }
      const data = await dispatch('apiGetBusinessPhoneTypes');
      await commit('setStateCompanyPhoneType', data);
      return data;
    },
    async loadStateCompanyDocumentType({commit, dispatch, getters}) {
      if (getters.stateCompanyDocumentType.length) {
        return getters.stateCompanyDocumentType;
      }
      const data = await dispatch('apiGetBusinessDocumentTypes');
      await commit('setStateCompanyDocumentType', data);
      return data;
    },
    async loadStateCompanies({commit, dispatch, getters}, {userId, isUpdate}) {
      if (getters.stateCompanies.length && !isUpdate) {
        return getters.stateCompanies;
      }
      const data = await dispatch('apiGetBusinesses', {user_id: userId});
      await commit('setStateCompanies', data);
      return data;
    },
  },
}
