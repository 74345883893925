import apiAxios from "@/api-axios";

export default {
  state: {
    customers: [],
    customersTotal: 0,
  },
  mutations: {
    setCustomers(state, responseData) {
      state.customers = responseData.users;
      state.customersTotal = responseData.total * 1;
    },
    setCustomersTotal(state, total) {
      state.customersTotal = total * 1;
    },
    clearCustomers(state) {
      state.customers = [];
      state.customersTotal = 0;
    }
  },
  getters: {
    customers: s => s.customers,
    customersTotal: s => s.customersTotal,
  },
  actions: {
    async apiGetCustomers({commit, dispatch}, params) {
      const defaultData = {users:[], total: 0};
      try {
        const response = await dispatch('apiGetCustomersRequest', params);
        await commit('setCustomers', response.data);
        return response.data;
      } catch (e) {
        await commit('setCustomers', defaultData);
        dispatch('catchError', e);
      }
      return defaultData;
    },
    async apiGetCustomersRequest(_, params) {
      return await apiAxios.get('/customers/', params);
    }

  }
}
