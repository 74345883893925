import apiAxios from "@/api-axios";

export default {
  actions: {
    async apiGetStates({dispatch}) {
      try {
        const response = await apiAxios.get('/statics/states/');
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return [];
    },
  }
}
