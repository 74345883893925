import apiAxios from "@/api-axios";

export default {
  state: {
    reportsMe: [],
    reportsMeData: {},
    historyReportsStatistics: [],
    reportId: '',
    activeHash: '',
    tabs: {},
    operator: {
      reports: [],
      countReports: 0,
    }
  },
  mutations: {
    setReportsMe(state, reports) {
      state.reportsMe = reports;
    },
    setReportsMeData(state, [reportId, reportsData]) {
      if (!state.reportsMeData[reportId]) {
        state.reportsMeData[reportId] = {};
      }
      state.reportsMeData[reportId] = reportsData;
    },
    clearReportsMeData(state) {
      state.reportsMe = [];
      state.reportsMeData = {};
    },
    setActiveReportId(state, reportId) {
      state.reportId = reportId;
    },
    changeSelectTab(state, {toggleName, tabName}) {
      if (!state.tabs[toggleName]) {
        state.tabs[toggleName] = '';
      }
      state.tabs[toggleName] = tabName;
    },
    clearSelectTabs(state) {
      state.tabs = {};
    },
    setActiveHash(state, hash) {
      state.activeHash = hash;
    },

    setOperatorReportsGroupBy(state, data) {
      state.operator.reports = data.reports;
      state.operator.countReports = data.count * 1;
    },
    setHistoryReportsStatistics(state, data) {
      state.historyReportsStatistics = data;
    },
  },
  getters: {
    activeHash: s => s.activeHash,
    reportId: s => s.reportId,
    reportsMe: s => s.reportsMe,
    reportsMeData: s => s.reportsMeData,
    historyReportsStatistics: s => s.historyReportsStatistics,
    tabs: s => s.tabs,

    operatorReportsGroupBy: s=>s.operator.reports,
    operatorCountReportsGroupBy: s=>s.operator.countReports,
  },
  actions: {
    async apiGetReportsMe({dispatch}, params) {
      try {
        const response = await apiAxios.get('/reports/me/', params);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return [];
    },
    async apiGetReports({dispatch}, params) {
      try {
        const response = await apiAxios.get('/reports/', params);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return [];
    },
    async apiGetReportMeData({dispatch}, reportId) {
      try {
        const response = await apiAxios.get(`/reports/me/data/${reportId}/`);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return {};
    },
    async apiGetReportData({dispatch}, reportId) {
      try {
        const response = await apiAxios.get(`/reports/data/${reportId}/`);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return {};
    },

    async apiGetOperatorReportsGroupBy({commit, dispatch}, params) {
      const defaultData = {reports: [], count: 0};
      try {
        const response = await apiAxios.get('/reports/group_by/', params);
        commit('setOperatorReportsGroupBy', response.data);
        return response.data;
      } catch (e) {
        commit('setOperatorReportsGroupBy', defaultData);
        dispatch('catchError', e);
      }
      return defaultData;
    },

    async apiGetReportStatistics({commit, dispatch}, params) {
      try {
        const response = await dispatch('apiGetReportStatisticsRequest', params);
        commit('setHistoryReportsStatistics', response.data);
        return response;
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiGetReportStatisticsRequest(_, params) {
      return await apiAxios.get('/reports/statistics/', params);
    },

  }
}
