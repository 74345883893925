import {createApp} from 'vue';
import route from '@/router';
import store from '@/store';
import '@/registerServiceWorker';

import Vue3MobileDetection from "vue3-mobile-detection";

import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';


//https://vuejsexamples.com/this-is-a-simple-vue-pluign-toast-notifier-with-tailwind/
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';


import App from "./components/App";

const app = createApp(App);

const UNIQUE_KEY_PROP = '__unique_key_prop__'
const KEY_PREFIX = '__key_prefix__' + Date.now() + '_'
let uid = 0

const isObject = val => val !== null && typeof val === 'object'

const uuid = obj => {
  if (isObject(obj)) {
    if (UNIQUE_KEY_PROP in obj) {
      return obj[UNIQUE_KEY_PROP]
    }
    const value = KEY_PREFIX + uid++
    Object.defineProperty(obj, UNIQUE_KEY_PROP, { value })
    return value
  }
  return obj
}

app.mixin({
  methods: { uuid: uuid }
});

app.component('v-select', vSelect);
app.use(VueToast).use(route).use(store).use(Vue3MobileDetection)
.mount('#app');


