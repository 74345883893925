import apiAxios from "@/api-axios";

export default {
  state: {
    phoneTypes: [],
    documentTypes: [],
    businessBureaus: [],
  },
  mutations: {
    setPhoneTypes(state, data) {
      state.phoneTypes = data;
    },
    clearPhoneTypes(state) {
      state.phoneTypes = [];
    },
    setDocumentTypes(state, data) {
      state.documentTypes = data;
    },
    clearDocumentTypes(state) {
      state.documentTypes = [];
    },
    setBusinessBureaus(state, data) {
      state.businessBureaus = data;
    }
  },
  getters: {
    phoneTypes: s=>s.phoneTypes,
    documentTypes: s=>s.documentTypes,
    businessBureaus: s=>s.businessBureaus,
  },
  actions: {
    async apiGetBusiness({dispatch}, businessId) {
      try {
        const response = await apiAxios.get(`/business/${businessId}/`);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return [];
    },
    async apiGetBusinesses({dispatch}, params) {
      try {
        const response = await apiAxios.get('/business/', params);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return [];
    },
    async apiGetBusinessesForUsers({dispatch}, params) {
      try {
        const response = await apiAxios.get('/business/for_users/', params, apiAxios.getParamsSerializerConfig());
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return [];
    },
    async apiGetBusinessPhoneTypes({dispatch, commit}) {
      try {
        const response = await apiAxios.get('/business/phone_types/');
        commit('setPhoneTypes', response.data);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return [];
    },
    async apiGetBusinessDocumentTypes({dispatch, commit}) {
      try {
        const response = await apiAxios.get('/business/document_types/');
        commit('setDocumentTypes', response.data);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return [];
    },
    async apiUploadBusinessDocument({dispatch}, {params, formData}) {
      try {
        const url = `/business/documents/?business_id=${encodeURIComponent(params.business_id)}&document_type_id=${encodeURIComponent(params.document_type_id)}`;
        const response = await apiAxios.axios.post(url, formData, {headers: {'Content-Type': 'multipart/form-data'}})
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return {};
    },
    async apiBusinessDocumentName({dispatch}, data) {
      try {
        const url = '/business/documents/name/';
        const response = await apiAxios.patch(url, data);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return {};
    },
    async apiCreateCompany({dispatch}, data) {
      try {
        const response = await apiAxios.post(`/business/`, data);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return {};
    },
    async apiDeleteCompany({dispatch}, id) {
      try {
        const response = await apiAxios.delete(`/business/${id}`);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return false;
    },
    async apiUpdateCompany({dispatch}, {business_id, data}) {
      try {
        const response = await apiAxios.patch(`/business/${business_id}`, data);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return {};
    },
    async apiGetBusinessBureaus({dispatch, commit}) {
      try {
        const response = await apiAxios.get('/business_bureaus/');
        commit('setBusinessBureaus', response.data);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return [];
    },
    async apiGetBusinessBureau({dispatch}, bureauId) {
      try {
        const response = await apiAxios.get(`/business_bureaus/${bureauId}`);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return {};
    },
    async apiGetBusinessBureauReportTypes({dispatch}, bureauId) {
      try {
        const response = await apiAxios.get(`/business_bureaus/${bureauId}/report_types/`);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return {};
    },
    async apiGetBusinessBureauForBusinessIds({dispatch}, business_ids) {
      try {
        const response = await apiAxios.get('/bureau_for_business/', {business_ids}, apiAxios.getParamsSerializerConfig());
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return {};
    },
    async apiGetBusinessBureauForBusinessById({dispatch}, id) {
      try {
        const response = await apiAxios.get(`/bureau_for_business/${id}/`);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return {};
    },
    async apiCreateBusinessBureau({dispatch}, data) {
      try {
        const response = await apiAxios.post('/bureau_for_business/', data);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return {};
    },
    async apiUpdateBusinessBureau({dispatch}, {id, data}) {
      try {
        const response = await apiAxios.put(`/bureau_for_business/${id}/`, data);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return {};
    },
    async apiSyncBusinessBureau({dispatch}, id) {
      try {
        const response = await apiAxios.get(`/bureau_for_business/sync/${id}/`);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return {};
    },
    async apiGetBusinessReports({dispatch}, params) {
      try {
        const response = await apiAxios.get('/business_reports/', params);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return [];
    },
    async apiGetBusinessReportData({dispatch}, id) {
      try {
        const response = await apiAxios.get(`/business_reports/${id}/`);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return [];
    },
  }
}
