<script setup>
import {computed, defineAsyncComponent, reactive, watch} from "vue";
import {useRoute} from "vue-router";
import GlobalPreload from "@/components/element/preload/GlobalPreload.vue";
import {useStore} from "vuex";
import {useToast} from "vue-toast-notification";

const $toast = useToast();
const $route = useRoute();
const $store = useStore();

const layout = computed(() => {
  if ($route.meta.layout === 'cs-main') {
    return defineAsyncComponent(()=>import("@/layouts/CsMain"));
  }
  if ($route.meta.layout === 'cs-auth') {
    return defineAsyncComponent(()=>import("@/layouts/CsAuth"));
  }
  return defineAsyncComponent(()=>import("@/layouts/CsDashboard"));
});

const getMessages = reactive($store.getters.messages);
watch(getMessages, async (newValue) => {
  Object.keys(newValue).forEach(type => {
    if (!newValue[type]) {
      return;
    }
    let options = {position: 'top-right'};
    switch (type){
      case 'error':
        $toast.error(newValue[type], options);
        $store.commit('clearMessage', type)
        break;
      case 'success':
        $toast.success(newValue[type], options);
        $store.commit('clearMessage', type)
        break;
      case 'info':
        $toast.info(newValue[type], options);
        $store.commit('clearMessage', type)
        break;
      case 'warning':
        $toast.warning(newValue[type], options);
        $store.commit('clearMessage', type)
        break;
      default:
        $toast.default(newValue[type], options);
        $store.commit('clearMessage', type)
    }
  });
});
</script>

<template>
  <component :is="layout" >
    <router-view></router-view>
  </component>
  <global-preload />
</template>

<style>
@import "@/assets/css/main.css";
</style>

<style scoped>

</style>

