import store from "@/store";
const globalPreloadType = 'global-preload';

function getStatusGlobalPreload() {
  return store.getters.preloader[globalPreloadType]||false;
}
async function onPreloader() {
  await store.commit('onPreloader', globalPreloadType);
}
async function offPreloader()  {
  await store.commit('offPreloader', globalPreloadType);
}
async function globalPreloadSwitcher(callback, ...arg)  {
  try {
    await onPreloader();
    await callback(...arg);
  }finally {
    await offPreloader();
  }
}
async function onGlobalPreloader()  {
  await onPreloader();
}
async function offGlobalPreloader()  {
  await offPreloader();
}
export {
  globalPreloadType,
  offGlobalPreloader, onGlobalPreloader,
  globalPreloadSwitcher, getStatusGlobalPreload
}
