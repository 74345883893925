import apiAxios from "@/api-axios";

export default {
  state: {
    bureaus: [],
    bureausAuth: {},
    fieldsAuth: {},
    bureausImages: {
      experianBusiness: require("@/assets/images/logo-experian-business.svg"),
      experian: require("@/assets/images/logo-experian.svg"),
      equifax: require("@/assets/images/logo-equifax.svg"),
      transunion: require("@/assets/images/logo-transunion.svg"),
      creditkarma: require("@/assets/images/logo-creditkarma.svg"),
    },
    bureauReportSmImages: {
      experianBusiness: require("@/assets/images/icons/logo-experian-sm.svg"),
      experian: require("@/assets/images/icons/logo-experian-sm.svg"),
      equifax: require("@/assets/images/icons/logo-equfax-sm.svg"),
      transunion: require("@/assets/images/icons/logo-tu-sm.svg"),
      creditkarma: require("@/assets/images/icons/logo-creditkarma-sm.svg"),
    },
    statusText:{
      FALSE: 'Synchronization is not started with this service.',
      SUCCESS: 'Synchronization was successful.',
      PROCESSING: 'Data is currently being collected.',
      PENDING: 'Waiting for synchronization.',
      OFFLINE: 'Synchronization is not started with this service.',
      CREATED: 'Synchronization task created.',
    },
    authStatus: {},
    reportStatus: {},
    reportAdditionalData: null,
    bureauReportTaskError: {auth:{},report:{}},
  },
  mutations: {
    setBureaus(state, bureaus) {
      state.bureaus = bureaus;
    },
    setBureausAuth(state, [bureauId, bureausAuth]){
      state.bureausAuth[bureauId] = bureausAuth;
    },
    setFieldsAuth(state, [bureauId, fields]) {
      state.fieldsAuth[bureauId] = fields;
    },
    setBureauStatus(state, data){
      if (data.auth) {
        if (
          data.status === 'SUCCESS' &&
          state.authStatus[data.bureau_id] !== data.status &&
          state.reportStatus[data.bureau_id] === 'FALSE'
        ) {
          state.reportStatus[data.bureau_id] = 'CREATED';
        }
        state.authStatus[data.bureau_id] = data.status;
      }else {
        if (state.authStatus[data.bureau_id] === 'SUCCESS') {
          state.reportStatus[data.bureau_id] = data.status;
          state.reportAdditionalData = data.additional_data;
        }else{
          state.reportStatus[data.bureau_id] = 'FALSE';
          state.reportAdditionalData = null;
        }
      }
    },
    setBureauTaskError(state, data) {
      if (data.auth){
        state.bureauReportTaskError.auth[data.bureau_id] = data.error;
      }else{
        state.bureauReportTaskError.report[data.bureau_id] = data.error;
      }
    },
  },
  getters: {
    bureaus: s => s.bureaus,
    bureausImages: s => s.bureausImages,
    bureauReportSmImages: s => s.bureauReportSmImages,

    authStatus: s=>s.authStatus,
    reportStatus: s=>s.reportStatus,
    reportAdditionalData: s=>s.reportAdditionalData,
    statusText: s=>s.statusText,
    bureauReportTaskError: s=>s.bureauReportTaskError,


    bureausAuth: s => s.bureausAuth,
    fieldsAuth: s => s.fieldsAuth,


  },
  actions: {
    async apiGetBureaus({commit, dispatch}, params) {
      try {
        const response = await apiAxios.get('/bureaus/', params);
        await commit('setBureaus', response.data);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return [];
    },
    async apiGetBureauStatusRequest(dispatchObject, params) {
      const response = await apiAxios.get('/users_bureaus/status/', params);
      let status = 'FALSE';
      let additionData = null;
      let errorTask = null;
      if (response.data.length) {
        status = response.data[0].task?.status;
        if (status === undefined) {
          status = 'FALSE';
        }
        additionData = response.data[0].bureau?.additional_data;
        if (additionData === undefined) {
          additionData = null;
        }

        errorTask = response?.data[0]?.task?.error;
      }
      return {
        responseData: response.data,
        bureauTaskError: {...params, error:errorTask},
        bureauStatus: {bureau_id:params.bureau_id, auth:params.auth, status, additional_data:additionData}
      };
    },
    async apiGetBureauStatus({commit, dispatch}, params) {
      try {
        const result = await dispatch('apiGetBureauStatusRequest', params);
        await commit('setBureauTaskError', result.bureauTaskError);
        await commit('setBureauStatus', result.bureauStatus);
        return result.responseData;
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiGetBureausWithLoadStatus({dispatch, getters}, [userId, params]) {
      try {
        await dispatch('apiGetBureaus', params);
        getters.bureaus.forEach(bureau => {
          const userData = {
            bureau_id:bureau.id,
            user_id:userId,
          };
          dispatch('apiGetBureauStatus', {auth:true, ...userData});
          dispatch('apiGetBureauStatus', {auth:false, ...userData});
        });
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async loadAuthFields({dispatch, commit}, bureau) {
      const bureauReportTypes = await dispatch("apiGetBureauReportTypes", bureau.id);
      const reportId = bureauReportTypes.find(type => type.name === 'auth');
      if (reportId && reportId?.id) {
        const fieldsAuth = await dispatch("apiGetBureauReportFields", reportId.id);
        commit('setFieldsAuth', [bureau.id, fieldsAuth])
      }
    },


    async apiGetUserBureaus({dispatch}, params) {
      try {
        const response = await apiAxios.get('/users_bureaus/', params);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
    },

    async apiGetUserBureausAuth({commit}, params) {
      try {
        const response = await apiAxios.get('/users_bureaus/auth/', params);
        commit('setBureausAuth', [params.bureau_id, response.data]);
        return response.data;
      }catch (e) {
        console.error('catchError', e);
      }
    },

    async apiSyncAllBureau(){

    },


    async apiGetBureauReportTypes({dispatch}, bureauId) {
      try {
        const response = await apiAxios.get(`/bureau_reports/${bureauId}/types/`);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiGetBureauReportFields({dispatch}, bureauReportTypId) {
      try {
        const response = await apiAxios.get(`/bureau_reports/${bureauReportTypId}/fields/`);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
    },

    async apiCreateUserBureau({dispatch}, data) {
      try {
        const response = await apiAxios.post('/users_bureaus/', data);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiUpdateUserBureau({dispatch}, data) {
      try {
        const response = await apiAxios.put(`/users_bureaus/${data.id}`, data);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiUpdateUserBureauAddData({dispatch}, {user_bureau_id, code}) {

      try {
        const response = await apiAxios.put(`/users_bureaus/${user_bureau_id}/add_data`, {code});
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiSyncUserBureau({dispatch}, uid) {
      try {
        const response = await apiAxios.get(`/users_bureaus/sync/${uid}`);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
    }
  }
}
