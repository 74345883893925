import axios from 'axios';
import store from '@/store';
import qs from "qs";

const API_URL = process.env.VUE_APP_API_URL;
const API_VERSION = process.env.VUE_APP_API_VERSION;

class axiosService {
  constructor() {
    let params = {
      baseURL: API_URL+API_VERSION,
    };
    this.axios = axios.create(params);
    setTimeout(async ()=>{
      if (!store.getters.accessToken && localStorage !== undefined && localStorage.getItem('token')) {
        await store.commit('setAccessToken', localStorage.getItem('token'))
        await store.dispatch('apiRefreshToken');
      }
    }, 100);

  }
  get(url, params, config) {
    return this.axios.get(url, {params, ...config});
  }
  post(url, data) {
    return this.axios.post(url, data);
  }
  put(url, data) {
    return this.axios.put(url, data);
  }
  patch(url, data) {
    return this.axios.patch(url, data);
  }
  delete(url) {
    return this.axios.delete(url);
  }

  setTokenHeader() {
    if (store.getters.accessToken) {
      this.axios.defaults.headers.common['Authorization'] = store.getters.accessToken;
      if (localStorage !== undefined && store.getters.accessToken !== localStorage.getItem('token')) {
        localStorage.setItem('token', store.getters.accessToken);
      }
    }
  }
  async removeToken() {
    delete this.axios.defaults.headers.common['Authorization'];
    if (localStorage !== undefined) {
      localStorage.removeItem('token');
    }
  }

  getParamsSerializerConfig() {
    const paramsSerializer = (params) => qs.stringify(params, {arrayFormat: "repeat"});
    return {paramsSerializer}
  }
}

export default new axiosService();
